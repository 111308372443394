import { useTranslation } from 'react-i18next'
import { ArrowDownCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'

import logo from '../assets/img/foresta.svg'
import logoWhite from '../assets/img/foresta-white.svg'
import logoBlack from '../assets/img/foresta-black.svg'
import qrCode from '../assets/img/qr-codes/foresta-home.png'

type GuidelineRule = {
  title: string
  description: string
}

export default function Guidelines() {
  const { t } = useTranslation()

  const rules = t<string, { returnObjects: true }, GuidelineRule[]>('guidelines.rules', {
    returnObjects: true,
  })

  const guidelines = [
    {
      image: logo,
      filename: 'foresta',
      background: 'bg-white',
      title: t('guidelines.icon.brand'),
      description: t('guidelines.icon.brand.description'),
    },
    {
      image: logoWhite,
      filename: 'foresta-white',
      background: 'bg-brand',
      title: t('guidelines.icon.white'),
      description: t('guidelines.icon.white.description'),
    },
    {
      image: logoWhite,
      filename: 'foresta-white',
      background: 'bg-gray-950',
      title: t('guidelines.icon.white'),
      description: t('guidelines.icon.white.description'),
    },
    {
      image: logoBlack,
      filename: 'foresta-black',
      background: 'bg-gray-100',
      title: t('guidelines.icon.black'),
      description: t('guidelines.icon.black.description'),
    },
  ]

  const qrCodes = [
    {
      image: qrCode,
      filename: 'foresta-qr-code',
      title: t('guidelines.qrCode.black'),
      url: 'http://foresta.energy/home',
      background: 'bg-white',
    },
  ]

  return (
    <section className="bg-white/0 dark:bg-slate-900 py-10 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-10">
        <div>
          <h1 className="text-4xl sm:text-6xl font-bold tracking-tight text-slate-900 dark:text-slate-100">
            {t('guidelines.title')}
          </h1>
          <p className="text-2xl font-medium text-gray-600 dark:text-gray-400">
            {t('guidelines.subtitle')}
          </p>
        </div>

        <div className="space-y-3">
          <h2 className="text-2xl sm:text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 ">
            {t('guidelines.icon')}
          </h2>
          <div className="flex flex-col gap-8">
            {guidelines.map((guideline, index) => (
              <div key={`guideline-${index}`} className="flex">
                <div className="mr-4 flex-shrink-0">
                  <img
                    src={guideline.image}
                    alt={guideline.title}
                    className={`h-24 w-24 border border-gray-200 dark:border-gray-800 p-4 ${guideline.background}`}
                  />
                </div>
                <div className="flex flex-col justify-between">
                  <div>
                    <h4 className="text-lg font-bold">{guideline.title}</h4>
                    <p className="mt-1 text-gray-500">{guideline.description}</p>
                  </div>
                  <a
                    href={guideline.image}
                    className="flex items-center gap-1 font-semibold text-brand hover:underline cursor-pointer"
                    download={guideline.filename}
                  >
                    <ArrowDownCircleIcon className="w-6 h-6" />
                    {t('guidelines.download')}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-3">
          <h2 className="text-2xl sm:text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100">
            {t('guidelines')}
          </h2>
          <ul className="space-y-4">
            {rules.map((rule, index) => (
              <li key={`rule-${index}`}>
                <div className="group flex gap-1">
                  <div>
                    <XCircleIcon className="w-6 h-6 mt-0.5 text-red-500" />
                  </div>
                  <div>
                    <h4 className="text-lg font-bold">{rule.title}</h4>
                    <p className="text-gray-500">{rule.description}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="space-y-3">
          <h2 className="text-2xl sm:text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 ">
            {t('guidelines.qrCode')}
          </h2>
          <div className="flex flex-col gap-8">
            {qrCodes.map((qrCode, index) => (
              <div key={`guideline-${index}`} className="flex">
                <div className="mr-4 flex-shrink-0">
                  <img
                    src={qrCode.image}
                    alt={qrCode.title}
                    className={`h-24 w-24 border border-gray-200 dark:border-gray-800 p-1 ${qrCode.background}`}
                  />
                </div>
                <div className="flex flex-col justify-between">
                  <div>
                    <h4 className="text-lg font-bold">{qrCode.title}</h4>
                    <p className="mt-1 text-gray-500">
                      <a href={qrCode.url} className="underline cursor-pointer">
                        {qrCode.url}
                      </a>
                    </p>
                  </div>
                  <a
                    href={qrCode.image}
                    className="flex items-center gap-1 font-semibold text-brand hover:underline cursor-pointer"
                    download={qrCode.filename}
                  >
                    <ArrowDownCircleIcon className="w-6 h-6" />
                    {t('guidelines.download')}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
