enum Language {
  English = 'en',
  French = 'fr',
}

const AvailableLanguages: { [key: string]: { nativeName: string } } = {
  [Language.English]: { nativeName: 'English' },
  [Language.French]: { nativeName: 'Français' },
};

export { Language, AvailableLanguages };
