import { useTranslation } from 'react-i18next'

import AbstractShape from './Shapes/AbstractShape'
import HandShake from './Icons/HandShake'
import HandHoldingDollar from './Icons/HandHoldingDollar'
import PlugPlusCircle from './Icons/PlugPlusCircle'
import MapLocationDot from './Icons/MapLocationDot'
import CalendarCheck from './Icons/CalendarCheck'
import ChargingStation from './Icons/ChargingStation'

export default function HowItWorks() {
  const { t } = useTranslation()

  const driverSteps = [
    {
      image: <MapLocationDot />,
      title: t('how-it-works.driver.step.1.title'),
      description: t('how-it-works.driver.step.1.description'),
    },
    {
      image: <CalendarCheck />,
      title: t('how-it-works.driver.step.2.title'),
      description: t('how-it-works.driver.step.2.description'),
    },
    {
      image: <ChargingStation />,
      title: t('how-it-works.driver.step.3.title'),
      description: t('how-it-works.driver.step.3.description'),
    },
  ]

  const hostSteps = [
    {
      image: <PlugPlusCircle />,
      title: t('how-it-works.host.step.1.title'),
      description: t('how-it-works.host.step.1.description'),
    },
    {
      image: <HandShake />,
      title: t('how-it-works.host.step.2.title'),
      description: t('how-it-works.host.step.2.description'),
    },
    {
      image: <HandHoldingDollar />,
      title: t('how-it-works.host.step.3.title'),
      description: t('how-it-works.host.step.3.description'),
    },
  ]

  return (
    <section
      id="how-it-works"
      className="relative isolate overflow-hidden bg-gray-900 py-10 sm:py-28"
    >
      <AbstractShape />
      <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center space-y-10">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
          {t('how-it-works.title')}
        </h2>

        <div className="flex flex-col items-center divide-y divide-slate-600">
          <div className="flex flex-col items-center gap-10 py-10">
            <div className="flex">
              <h3 className="text-white/75 bg-slate-700 rounded-full px-4 py-2">
                {t('how-it-works.driver')}
              </h3>
            </div>
            <div className="flex flex-col md:flex-row gap-6">
              {driverSteps.map((steps, index) => (
                <div
                  key={`step-${index}`}
                  className="flex flex-col items-center justify-center text-center gap-2 w-full md:w-1/3"
                >
                  <div className="w-16 h-16 fill-white">{steps.image}</div>
                  <h3 className="text-xl text-white">{steps.title}</h3>
                  <p className="text-gray-400">{steps.description}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col items-center gap-10 py-10">
            <div className="flex">
              <h3 className="text-white/75 bg-slate-700 rounded-full px-4 py-2">
                {t('how-it-works.host')}
              </h3>
            </div>
            <div className="flex flex-col md:flex-row gap-6">
              {hostSteps.map((steps, index) => (
                <div
                  key={`step-${index}`}
                  className="flex flex-col items-center justify-center text-center gap-2 w-full md:w-1/3"
                >
                  <div className="w-16 h-16 fill-white">{steps.image}</div>
                  <h3 className="text-xl text-white">{steps.title}</h3>
                  <p className="text-gray-400">{steps.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
