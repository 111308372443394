import { useTranslation } from 'react-i18next'

import { Language } from '../../types/Language'
import googlePlayEn from '../../assets/img/badges/google-play-en.png'
import googlePlayFr from '../../assets/img/badges/google-play-fr.png'

type Props = {
  className?: string
}

export default function GooglePlayBadge({ className }: Props) {
  const { i18n } = useTranslation()

  return (
    <a href={process.env.REACT_APP_GOOGLE_PLAY_URL} target="_blank" rel="noreferrer">
      <img
        src={i18n.resolvedLanguage === Language.French ? googlePlayFr : googlePlayEn}
        alt="Google Play"
        className={`${className}`}
      />
    </a>
  )
}
