import { useTranslation } from 'react-i18next'

import { Language } from '../types/Language'
import StoreBadges from '../components/Badges/StoreBadges'
import Phone from '../components/Phone/Phone'
import fleet from '../assets/img/solutions/fleet.jpg'
import multifamily from '../assets/img/solutions/multifamily.jpg'
import hospitality from '../assets/img/solutions/hospitality.jpg'
import screenshot1En from '../assets/img/screenshots/en/screenshot-1.png'
import screenshot2En from '../assets/img/screenshots/en/screenshot-2.png'
import screenshot3En from '../assets/img/screenshots/en/screenshot-3.png'
import screenshot1Fr from '../assets/img/screenshots/fr/screenshot-1.png'
import screenshot2Fr from '../assets/img/screenshots/fr/screenshot-2.png'
import screenshot3Fr from '../assets/img/screenshots/fr/screenshot-3.png'

export default function Solutions() {
  const { t, i18n } = useTranslation()

  const screenshot1 = i18n.resolvedLanguage === Language.French ? screenshot1Fr : screenshot1En
  const screenshot2 = i18n.resolvedLanguage === Language.French ? screenshot2Fr : screenshot2En
  const screenshot3 = i18n.resolvedLanguage === Language.French ? screenshot3Fr : screenshot3En

  const screenshots = [screenshot1, screenshot2, screenshot3]

  const solutions = [
    {
      id: 'fleet',
      image: fleet,
      title: t('solutions.fleet'),
      description: t('solutions.fleet.description'),
    },
    {
      id: 'multifamily',
      image: multifamily,
      title: t('solutions.multifamily'),
      description: t('solutions.multifamily.description'),
    },
    {
      id: 'hospitality',
      image: hospitality,
      title: t('solutions.hospitality'),
      description: t('solutions.hospitality.description'),
    },
  ]

  return (
    <section id="solutions" className="bg-slate-200 dark:bg-slate-800 py-10 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-10">
        <h2 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-6xl">
          {t('solutions.title')}
        </h2>

        <div className="space-y-4">
          <h3 className="text-2xl font-medium text-gray-600 dark:text-gray-400">
            {t('solutions.business')}
          </h3>

          <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
            {solutions.map((solution, index) => (
              <div
                id={solution.id}
                key={`solution-${index}`}
                className="bg-slate-50 dark:bg-slate-900 rounded-lg shadow-md overflow-hidden"
              >
                <div className="relative">
                  <img
                    src={solution.image}
                    alt={solution.title}
                    className="w-full h-60 object-cover object-top"
                    loading="lazy"
                  />
                  <div className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-slate-900/50">
                    <h3 className="text-xl text-white font-medium">{solution.title}</h3>
                  </div>
                </div>
                <p className="text-gray-700 dark:text-gray-300 p-3">{solution.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-center my-3">
          <a
            href="https://calendly.com/team-foresta/30min"
            className="rounded-md bg-brand px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand cursor-pointer"
            target="_blank"
            rel="noreferrer"
          >
            {t('solutions.bookCall')}
          </a>
        </div>

        <div className="space-y-4">
          <h3 className="text-2xl font-medium text-gray-600 dark:text-gray-400">
            {t('solutions.customer')}
          </h3>

          <div className="bg-slate-50 dark:bg-slate-900 rounded-lg shadow-md">
            <div className="flex flex-col md:flex-row">
              <div className="flex flex-col gap-4 justify-between p-3 order-last md:order-first">
                <div className="space-y-4">
                  <p className="text-gray-700 dark:text-gray-300">
                    {t('solutions.home.description')}
                  </p>
                  <div className="flex justify-center">
                    <a
                      href="/home"
                      className="px-3.5 py-2.5 text-sm font-semibold text-brand hover:underline cursor-pointer"
                    >
                      {t('learnMore')}
                    </a>
                  </div>
                </div>
                <div className="flex flex-col items-center md:items-start">
                  <StoreBadges display="horizontal" />
                </div>
              </div>

              <div className="flex justify-between gap-2 p-3">
                {screenshots.map((screenshot, index) => (
                  <div key={`screenshot-${index}`} className="w-36">
                    <Phone screenshot={screenshot} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
