import Header from '../components/Header/SimpleHeader'
import Faq from '../components/Faq'
import Footer from '../components/Footer'

export default function FaqPage() {
  return (
    <>
      <Header />
      <Faq />
      <Footer />
    </>
  )
}
