import { useTranslation } from 'react-i18next'

import Markdown from './Markdown/Markdown'

const FILE_NAME = 'cancellation-policy.md'

export default function CancellationPolicy() {
  const { t } = useTranslation()

  return (
    <div className="space-y-4">
      <div className="markdown text-gray-950 dark:text-white">
        <strong>{t('cancellationPolicy.title')}</strong>
      </div>
      <Markdown fileName={FILE_NAME} />
    </div>
  )
}
