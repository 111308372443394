import AppStoreBadge from './AppStoreBadge'
import GooglePlayBadge from './GooglePlayBadge'

type Props = {
  display: 'vertical' | 'horizontal'
}

export default function StoreBadges({ display }: Props) {
  const image = display === 'horizontal' ? 'h-11' : 'w-36'
  const flex = display === 'horizontal' ? 'flex-row space-x-2' : 'flex-col space-y-2'

  return (
    <div className={`flex ${flex}`}>
      <AppStoreBadge className={image} />
      <GooglePlayBadge className={image} />
    </div>
  )
}
