import { PropsWithChildren } from 'react'
import CheckCircleIcon from '@heroicons/react/24/solid/CheckCircleIcon'
import XCircleIcon from '@heroicons/react/24/solid/XCircleIcon'

interface Props extends PropsWithChildren {
  title?: string
  intent?: MessageIntent
}

export enum MessageIntent {
  Error,
  Success,
}

export default function Message({ title, intent = MessageIntent.Error, children }: Props) {
  if (intent === MessageIntent.Success) {
    return (
      <div className="rounded-md bg-green-50 dark:bg-green-950 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400 dark:text-green-600"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800 dark:text-green-200">{title}</h3>
            {children && (
              <div className="mt-2 text-sm text-green-700 dark:text-green-300">{children}</div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="rounded-md bg-red-50 dark:bg-red-950 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400 dark:text-red-600" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800 dark:text-red-200">{title}</h3>
          <div className="mt-2 text-sm text-red-700 dark:text-red-300">{children}</div>
        </div>
      </div>
    </div>
  )
}
