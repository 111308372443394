import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Header from '../components/Header/HomeHeader'
import HowItWorks from '../components/HowItWorks'
import Features from '../components/Features'
import Faq from '../components/Faq'
import ScreenShots from '../components/ScreenShots'
import Footer from '../components/Footer'
import Contact from '../components/Contact'

export default function HomePage() {
  const { pathname, hash, key } = useLocation()

  useEffect(() => {
    // scrool to element Id if using # links
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }, [pathname, hash, key])

  return (
    <>
      <Header />
      <Features />
      <HowItWorks />
      <ScreenShots />
      <Faq />
      <Contact />
      <Footer />
    </>
  )
}
