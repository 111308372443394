import { PhotoModel } from '../types/PhotoModel'

interface Props {
  photos: Array<PhotoModel>
}

export default function LocationPhotosGallery({ photos }: Props) {
  const photo = photos[0]

  return (
    <div key={`photo-${photo.id}`} className="">
      <img
        src={`${process.env.REACT_APP_COMMON_STORAGE_URI}${photo.url}`}
        alt=""
        className="w-full h-full object-cover object-top"
        loading="lazy"
      />
    </div>
  )
}
