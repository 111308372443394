export default function Nema515() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="11.5" stroke="currentColor" strokeWidth="2" />
      <rect x="6.46533" y="5.60352" width="2.15517" height="7.75862" fill="currentColor" />
      <rect x="15.9482" y="5.60352" width="2.15517" height="9.05172" fill="currentColor" />
      <rect x="11.2068" y="17.6724" width="2.58621" height="3.01724" fill="currentColor" />
      <circle cx="12.4999" cy="17.6724" r="1.2931" fill="currentColor" />
    </svg>
  )
}
