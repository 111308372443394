import { createContext, useContext, useState, PropsWithChildren, useEffect } from 'react'
import { ColorScheme } from '../types/ColorScheme'

type ContextValue = {
  colorScheme: ColorScheme
}

interface ColorSchemeProviderProps extends PropsWithChildren {}

const ColorSchemeContext = createContext<ContextValue>({} as ContextValue)

export function ColorSchemeProvider({ children }: ColorSchemeProviderProps) {
  const [colorScheme, setColorScheme] = useState(ColorScheme.Light)

  useEffect(() => {
    const darkModePreference = window.matchMedia('(prefers-color-scheme: dark)')
    setColorScheme(darkModePreference.matches ? ColorScheme.Dark : ColorScheme.Light)

    darkModePreference.addEventListener('change', (event) => {
      setColorScheme(event.matches ? ColorScheme.Dark : ColorScheme.Light)
    })
  }, [])

  return (
    <ColorSchemeContext.Provider value={{ colorScheme }}>{children}</ColorSchemeContext.Provider>
  )
}

export function useColorScheme() {
  const context = useContext(ColorSchemeContext)
  if (context === undefined) {
    throw new Error('useColorScheme must be used within a ColorSchemeProvider')
  }
  return context
}
