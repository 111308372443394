import { useParams } from 'react-router-dom'

import Header from '../components/Header/HomeHeader'
import Unsubscribe from '../components/Unsubscribe'
import Footer from '../components/Footer'

export default function UnsubscribePage() {
  let params = useParams()

  return (
    <>
      <Header />
      <Unsubscribe code={params.code} />
      <Footer />
    </>
  )
}
