import Header from '../components/Header/SimpleHeader'
import PrivacyPolicy from '../components/PrivacyPolicy'
import Footer from '../components/Footer'

export default function PrivacyPolicyPage() {
  return (
    <>
      <Header />
      <PrivacyPolicy />
      <Footer />
    </>
  )
}
