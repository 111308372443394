import { useTranslation } from 'react-i18next'

import { Language } from '../../types/Language'
import appStoreEn from '../../assets/img/badges/app-store-en.svg'
import appStoreFr from '../../assets/img/badges/app-store-fr.svg'

type Props = {
  className?: string
}

export default function AppStoreBadge({ className }: Props) {
  const { i18n } = useTranslation()

  return (
    <a href={process.env.REACT_APP_APP_STORE_URL} target="_blank" rel="noreferrer">
      <img
        src={i18n.resolvedLanguage === Language.French ? appStoreFr : appStoreEn}
        alt="App Store"
        className={`${className}`}
      />
    </a>
  )
}
