import Header from '../components/Header/SimpleHeader'
import Terms from '../components/Terms'
import Footer from '../components/Footer'

export default function TermsConditionsPage() {
  return (
    <>
      <Header />
      <Terms />
      <Footer />
    </>
  )
}
