import { useParams } from 'react-router-dom'

import Header from '../components/Header/HomeHeader'
import Location from '../components/Location'
import Footer from '../components/Footer'

export default function LocationPage() {
  let params = useParams()

  return (
    <>
      <Header />
      <Location id={params.id?.toLowerCase()} />
      <Footer />
    </>
  )
}
