import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AvailableLanguages } from '../../types/Language'

export default function LocaleSwitcher() {
  const { i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.resolvedLanguage)

  return (
    <select
      name="language"
      value={selectedLanguage}
      className="locale-switcher bg-white/25 text-xs text-white border-none rounded-full ring-1 ring-inset ring-white/30 focus:ring-2 focus:ring-inset focus:ring-brand focus:outline-none"
      onChange={(e) => {
        i18n.changeLanguage(e.target.value)
        setSelectedLanguage(e.target.value)
      }}
    >
      {Object.keys(AvailableLanguages).map((lng) => (
        <option key={lng} value={lng} className="text-sm text-white">
          {AvailableLanguages[lng].nativeName}
        </option>
      ))}
    </select>
  )
}
