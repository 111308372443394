import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Header from '../components/Header/Header'
import Solutions from '../components/Solutions'
import Partner from '../components/Partner'
import Contact from '../components/Contact'
import Footer from '../components/Footer'

export default function App() {
  const { pathname, hash, key } = useLocation()

  useEffect(() => {
    // scrool to element Id if using # links
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }, [pathname, hash, key])

  return (
    <>
      <Header />
      <Solutions />
      <Partner />
      <Contact becomePartner />
      <Footer />
    </>
  )
}
