import Header from '../components/Header/SimpleHeader'
import Guidelines from '../components/Guidelines'
import Footer from '../components/Footer'

export default function GuidelinesPage() {
  return (
    <>
      <Header />
      <Guidelines />
      <Footer />
    </>
  )
}
