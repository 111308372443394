export default function Tesla() {
  return (
    <svg width="31" height="25" viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.0405 18.3702C13.9313 11.9996 7.11244 17.9293 6.6224 18.5058C6.13235 19.0823 4.74862 16.5168 4.74862 16.5168L4.20098 14.7584C4.20098 14.7584 3.76857 13.1153 4.20096 13.1441C4.63335 13.1729 6.70881 14.1242 8.52486 13.6341C10.3409 13.1441 10.9011 13.0524 12.3876 11.0398C13.874 9.02722 13.7712 5.24573 10.3986 3.17025C9.70673 2.53608 10.9751 2.68022 12.0993 2.68022H18.0825C19.2067 2.68022 20.5296 2.53535 19.8378 3.16952C16.4651 5.24501 16.3623 9.02649 17.8488 11.0391C19.3353 13.0516 19.8955 13.1434 21.7115 13.6334C23.5276 14.1235 25.603 13.1722 26.0354 13.1434C26.4678 13.1145 26.0354 14.7576 26.0354 14.7576L25.4878 16.516C25.4878 16.516 24.0024 19.2561 23.5124 18.6795L23.0405 18.3702Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.69605 6.40286C6.87671 6.40286 6.11005 7.09457 6.11005 8.07294C6.11005 9.05131 6.87671 9.74301 7.69605 9.74301C8.51538 9.74301 9.28204 9.05131 9.28204 8.07294C9.28204 7.09457 8.51538 6.40286 7.69605 6.40286ZM3.69604 8.07294C3.69604 5.91981 5.43032 4.07294 7.69605 4.07294C9.96178 4.07294 11.696 5.91981 11.696 8.07294C11.696 10.2261 9.96178 12.0729 7.69605 12.0729C5.43032 12.0729 3.69604 10.2261 3.69604 8.07294Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3848 6.41788C21.5654 6.41788 20.7988 7.10958 20.7988 8.08795C20.7988 9.06632 21.5654 9.75803 22.3848 9.75803C23.2041 9.75803 23.9708 9.06632 23.9708 8.08795C23.9708 7.10958 23.2041 6.41788 22.3848 6.41788ZM18.3848 8.08795C18.3848 5.93482 20.119 4.08795 22.3848 4.08795C24.6505 4.08795 26.3848 5.93483 26.3848 8.08795C26.3848 10.2411 24.6505 12.088 22.3848 12.088C20.119 12.088 18.3848 10.2411 18.3848 8.08795Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2348 1C18.7857 1 23.934 1 26.2681 3.02676C29.0293 5.42443 29.4132 8.35275 29.3133 11.342C29.2021 14.6677 27.9078 18.0124 25.3133 20.3594C22.6899 22.7326 19.0143 24.0808 15.3132 24.0469C11.4819 24.0117 7.46935 22.8694 4.81327 20.3594C2.37518 18.0554 0.898977 14.5379 0.813274 11.342C0.734569 8.40711 1.34427 5.58834 3.46132 3.17856C5.04834 1.3721 10.1095 1 11.481 1C14.9095 1 13.1205 1 17.2348 1Z"
        stroke="currentColor"
        strokeWidth="1.59503"
      />
      <circle cx="15.2222" cy="19.088" r="2" fill="currentColor" />
      <circle cx="9.33032" cy="19.4977" r="1" fill="currentColor" />
      <circle cx="21.071" cy="19.4977" r="1" fill="currentColor" />
    </svg>
  )
}
