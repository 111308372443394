import { useTranslation } from 'react-i18next'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

import DeepLinkRedirection from '../components/DeepLinkRedirection'

export default function StripeConfirmationPage() {
  const { t } = useTranslation()

  const deepLink = 'foresta://account/host_earnings'

  return (
    <DeepLinkRedirection
      deepLink={deepLink}
      icon={<CheckCircleIcon className="w-16 text-green-500" />}
      title={t('stripe.title')}
      subtitle={t('stripe.subtitle')}
      showStoreLinks={false}
    />
  )
}
