import { useTranslation } from 'react-i18next'
import { RocketLaunchIcon, PresentationChartLineIcon, BoltIcon } from '@heroicons/react/24/outline'

import AbstractShape from '../components/Shapes/AbstractShape'

export default function Partner() {
  const { t } = useTranslation()
  const cards = [
    {
      name: t('parner.feature.1.title'),
      description: t('parner.feature.1.description'),
      icon: RocketLaunchIcon,
    },
    {
      name: t('parner.feature.2.title'),
      description: t('parner.feature.2.description'),
      icon: PresentationChartLineIcon,
    },
    {
      name: t('parner.feature.3.title'),
      description: t('parner.feature.3.description'),
      icon: BoltIcon,
    },
  ]

  return (
    <section id="partner" className="relative isolate overflow-hidden bg-gray-900 py-10 sm:py-40">
      <AbstractShape />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
          {t('parner.title')}
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-300">{t('parner.title.description')}</p>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
          {cards.map((card) => (
            <div
              key={card.name}
              className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10"
            >
              <card.icon className="h-7 w-5 flex-none text-brand" aria-hidden="true" />
              <div className="text-base leading-7">
                <h3 className="font-semibold text-white">{card.name}</h3>
                <p className="mt-2 text-gray-300">{card.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center justify-center mt-5">
        <a
          href="#contact"
          className="rounded-md bg-brand px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand cursor-pointer"
        >
          {t('menu.becomePartner')}
        </a>
      </div>
    </section>
  )
}
