import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isAndroid, isIOS, isMacOs } from 'react-device-detect'

import { SocialLocationModel } from '../types/SocialLocationModel'
import LocationStations from './LocationStations'
import LocationPhotosGallery from './LocationPhotosGallery'
import { LoadingIcon } from './Loading/LoadingIcon'
import Message, { MessageIntent } from './Message/Message'

interface Props {
  id: string | undefined
}

export default function Location({ id }: Props) {
  const { t } = useTranslation()
  const [success, setSuccess] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [location, setLocation] = useState<SocialLocationModel | undefined>(undefined)

  useEffect(() => {
    const fetchLocation = async (id: string) => {
      const url = `${process.env.REACT_APP_API_URL}/Locations/social/${id}`

      await fetch(url)
        .then((res) => res.json())
        .then((data) => {
          if (data.message) {
            setSuccess(false)
            setErrorMessage(data.message)
          } else {
            setSuccess(true)
            setLocation(data)
          }
        })
        .catch((err) => {
          console.error('Error: ', err)
          setSuccess(false)
        })
    }

    if (id) fetchLocation(id)
  }, [id])

  const fullAdress = useMemo(() => {
    if (location === undefined) return ''
    return `${location.address}, ${location.city}, ${location.state}, ${location.postalCode}`
  }, [location])

  const bookURrl = useMemo(() => {
    if (location === undefined) return undefined
    if (isIOS || isAndroid) return `foresta://explore/locations/${id}`
    return process.env.REACT_APP_APP_STORE_URL
  }, [location, id])

  const mapUrl = useMemo(() => {
    if (location === undefined) return undefined
    if (isIOS || isMacOs) return `http://maps.apple.com/?address=${fullAdress}`
    return `https://maps.google.com/maps?q=${fullAdress}`
  }, [location, fullAdress])

  return (
    <section className="bg-white/0 dark:bg-black py-10 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-10">
        <div className="flex flex-col justify-center items-center gap-4">
          {success === undefined && (
            <div className="group flex items-center gap-2">
              <div className="w-8 h-8">
                <LoadingIcon color="text-gray-500" />
              </div>
              <span className="text-gray-500 text-lg">{t('loading')}</span>
            </div>
          )}
          {success === false && (
            <Message intent={MessageIntent.Error} title={t('error.title')}>
              {t('error.message')}
              {errorMessage}
            </Message>
          )}
          {success === true && location !== undefined && (
            <>
              <div>
                <h1 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-6xl text-center">
                  {location.name}
                </h1>
                <p>
                  <a href={mapUrl}>{fullAdress}</a>
                </p>
              </div>

              <div className="flex gap-4 h-80 overflow-hidden">
                <div className="w-1/2">
                  <LocationPhotosGallery photos={location.photos} />
                </div>
                <div className="w-1/2">
                  <iframe
                    title={t('location.map')}
                    src={`https://maps.google.com/maps?q=${fullAdress}&t=&z=16&ie=UTF8&output=embed`}
                    allowFullScreen
                    className="w-full h-full"
                  />
                </div>
              </div>

              <LocationStations evses={location.evses} />

              {bookURrl !== undefined && (
                <div className="flex items-center justify-center">
                  <a
                    href={bookURrl}
                    className="rounded-md bg-brand px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
                  >
                    {t('location.book')}
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  )
}
