import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isAndroid, isIOS } from 'react-device-detect'

import { LoadingIcon } from './Loading/LoadingIcon'
import StoreBadges from './Badges/StoreBadges'

interface Props {
  deepLink: string
  icon?: JSX.Element
  title?: string
  subtitle?: string
  showStoreLinks?: boolean
}

export default function DeepLinkRedirection({
  deepLink,
  icon,
  title,
  subtitle,
  showStoreLinks = true,
}: Props) {
  const { t } = useTranslation()
  const [willRedirect, setWillRedirect] = useState(true)
  const isMobile = isIOS || isAndroid

  const pageTile = title ?? t('home.title')
  const pageSubtitle = subtitle ?? t('home.subtitle')

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isMobile) {
        window.location.href = deepLink
      }
      setWillRedirect(false)
    }, 1000)
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className="bg-white/0 dark:bg-black py-40 sm:py-60">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-10">
        <div className="flex flex-col justify-center items-center gap-2">
          {icon && <div>{icon}</div>}
          <div className="mb-8">
            <h1 className="text-2xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-4xl text-center">
              {pageTile}
            </h1>
            <p className="text-base text-center leading-7 text-gray-600 dark:text-gray-400">
              {pageSubtitle}
            </p>
          </div>

          {showStoreLinks && <StoreBadges display="horizontal" />}

          {isMobile && willRedirect && (
            <div className="group flex items-center gap-2 mt-4 text-gray-500">
              <div className="w-8 h-8">
                <LoadingIcon color="text-gray-500" />
              </div>
              <span>{t('redirecting')}</span>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
