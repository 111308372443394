import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import { AvailableLanguages } from '../../types/Language'
import './Markdown.css'

type Props = {
  fileName: string
}

export default function Markdown({ fileName }: Props) {
  const { i18n } = useTranslation()
  const [text, setText] = useState<Map<string, string>>(new Map())

  useEffect(() => {
    const getTranslatedTexts = async () => {
      let map: Map<string, string> = new Map()

      for (const lng of Object.keys(AvailableLanguages)) {
        await fetch(`locales/${lng}/${fileName}`)
          .then((r) => r.text())
          .then((text) => map.set(lng, text))
      }
      setText(map)
    }

    getTranslatedTexts()
  }, [fileName])

  return (
    <ReactMarkdown className="markdown text-gray-950 dark:text-white">
      {text.get(i18n.resolvedLanguage ?? '') ?? ''}
    </ReactMarkdown>
  )
}
