import { SVGIntrinsicProps } from '../../../types/SVGIntrinsicProps'

export default function MTLab(props: SVGIntrinsicProps) {
  return (
    <svg fill="currentColor" viewBox="0 0 138 83" {...props}>
      <path
        d="M0 0 C2.31 0 4.62 0 7 0 C10.3 6.93 13.6 13.86 17 21 C18.093125 18.834375 19.18625 16.66875 20.3125 14.4375 C20.65063721 13.77032959 20.98877441 13.10315918 21.3371582 12.41577148 C23.38596434 8.33664917 25.23850631 4.21132491 27 0 C29.64 0 32.28 0 35 0 C35 12.87 35 25.74 35 39 C32.69 39 30.38 39 28 39 C27.67 32.07 27.34 25.14 27 18 C24.69 22.62 22.38 27.24 20 32 C18.02 32 16.04 32 14 32 C11.69 27.38 9.38 22.76 7 18 C6.67 24.93 6.34 31.86 6 39 C4.02 39 2.04 39 0 39 C0 26.13 0 13.26 0 0 Z "
        transform="translate(0,0)"
      />
      <path
        d="M0 0 C5.0428125 -0.0928125 5.0428125 -0.0928125 10.1875 -0.1875 C11.76374146 -0.22846802 11.76374146 -0.22846802 13.37182617 -0.27026367 C22.00620296 -0.34726022 22.00620296 -0.34726022 26 3 C28.56632517 6.42176689 28.36435051 9.84640421 28 14 C26.5 16.9375 26.5 16.9375 25 19 C25.48339844 19.54785156 25.96679688 20.09570312 26.46484375 20.66015625 C28.29487909 23.44944676 28.4935627 25.12391402 28.5625 28.4375 C28.60503906 29.36433594 28.64757812 30.29117188 28.69140625 31.24609375 C27.89303265 34.42605639 26.65977815 36.09140458 24.0300293 38.06030273 C19.79527676 40.02056281 15.18886815 39.50240908 10.625 39.3125 C7.11875 39.209375 3.6125 39.10625 0 39 C0 26.13 0 13.26 0 0 Z M7 6 C7 9.3 7 12.6 7 16 C8.77007714 16.08114106 10.54122098 16.13925505 12.3125 16.1875 C13.29863281 16.22230469 14.28476563 16.25710937 15.30078125 16.29296875 C18.015368 16.22387958 18.015368 16.22387958 19.8671875 14.6484375 C21.26685794 12.97954571 21.26685794 12.97954571 20.8125 10.4375 C20.15372967 7.85558728 20.15372967 7.85558728 18 6 C15.33050591 5.75684344 15.33050591 5.75684344 12.375 5.875 C11.37210938 5.89304687 10.36921875 5.91109375 9.3359375 5.9296875 C8.17964844 5.96449219 8.17964844 5.96449219 7 6 Z M7 22 C7 25.63 7 29.26 7 33 C8.93627769 33.10807131 10.87427756 33.1856225 12.8125 33.25 C13.89144531 33.29640625 14.97039062 33.3428125 16.08203125 33.390625 C19.08761094 33.28099279 19.08761094 33.28099279 20.92578125 31.1484375 C22.29385173 28.98720038 22.29385173 28.98720038 21.375 26.375 C20.07948232 23.73365529 20.07948232 23.73365529 17 22 C14.48415109 21.75849227 14.48415109 21.75849227 11.8125 21.875 C10.224375 21.91625 8.63625 21.9575 7 22 Z "
        transform="translate(105,44)"
      />
      <path
        d="M0 0 C2.31 0 4.62 0 7 0 C8.99582917 5.53516529 10.98960434 11.07106431 12.98144531 16.60766602 C13.6599113 18.49263707 14.33894686 20.37740321 15.01855469 22.26196289 C15.99302145 24.96447456 16.96546455 27.66770679 17.9375 30.37109375 C18.24373291 31.21900986 18.54996582 32.06692596 18.86547852 32.9405365 C21 38.88612829 21 38.88612829 21 40 C18.36 40 15.72 40 13 40 C10.03 31.42 7.06 22.84 4 14 C1.3280205 20.01195387 -1.12224964 25.86922662 -3.25 32.0625 C-3.518125 32.83271484 -3.78625 33.60292969 -4.0625 34.39648438 C-4.71176859 36.26313158 -5.35639263 38.13139328 -6 40 C-8.64 40 -11.28 40 -14 40 C-9.61928064 26.55915651 -4.88941145 13.2637892 0 0 Z "
        transform="translate(81,33)"
      />
      <path
        d="M0 0 C9.57 0 19.14 0 29 0 C29 1.98 29 3.96 29 6 C25.37 6 21.74 6 18 6 C18 16.89 18 27.78 18 39 C15.69 39 13.38 39 11 39 C11 28.11 11 17.22 11 6 C7.37 6 3.74 6 0 6 C0 4.02 0 2.04 0 0 Z "
        transform="translate(38,0)"
      />
      <path
        d="M0 0 C2.31 0 4.62 0 7 0 C7 10.89 7 21.78 7 33 C13.27 33 19.54 33 26 33 C26 34.98 26 36.96 26 39 C17.42 39 8.84 39 0 39 C0 26.13 0 13.26 0 0 Z "
        transform="translate(49,44)"
      />
    </svg>
  )
}
