import React, { useState } from 'react'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

type FaqQuestion = {
  question: string
  answer: string
}

export default function Faq() {
  const { t, i18n } = useTranslation()
  const [search, setSearch] = useState<string>('')

  const faqs = t<string, { returnObjects: true }, FaqQuestion[]>('faq.questions', {
    returnObjects: true,
  })

  i18n.on('languageChanged', () => {
    setSearch('')
  })

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value
    setSearch(searchText)
  }

  return (
    <section id="faq" className="bg-slate-50 dark:bg-slate-950 py-10 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-6xl text-center">
          {t('faq.title')}
        </h2>
        <div className="mx-10 md:mx-48 pt-5 md:pt-10">
          <input
            type="search"
            value={search}
            className="block w-full rounded-3xl border-0 py-1.5 px-3 bg-white dark:bg-slate-900 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6 focus:outline-none"
            placeholder={t('faq.search')}
            onChange={onSearch}
          />
        </div>

        <dl className="mt-5 space-y-6 divide-y divide-gray-900/10 dark:divide-white/10">
          {faqs
            .filter(
              (faq) =>
                faq.question.toLowerCase().includes(search.toLowerCase()) ||
                faq.answer.toLowerCase().includes(search.toLowerCase())
            )
            .map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <DisclosureButton className="flex w-full items-start justify-between text-left text-gray-900 dark:text-white">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600 dark:text-gray-400">
                        {faq.answer}
                      </p>
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            ))}
        </dl>
      </div>
    </section>
  )
}
