import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { EnvelopeIcon } from '@heroicons/react/24/solid'

import { SVGIntrinsicProps } from '../types/SVGIntrinsicProps'
import StoreBadges from './Badges/StoreBadges'
import logoForesta from '../assets/img/foresta-white.svg'
import Facebook from './Icons/Social/Facebook'
import LinkedIn from './Icons/Social/LinkedIn'
import MTLab from './Icons/Incubators/MTLab'
import CoopCarbone from './Icons/Incubators/Coop'
import DeuxDegres from './Icons/Incubators/DeuxDegres'

export default function Footer() {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()

  const navigation = {
    solutions: [
      { name: t('solutions.fleet'), href: '/#fleet' },
      { name: t('solutions.multifamily'), href: '/#multifamily' },
      { name: t('solutions.hospitality'), href: '/#hospitality' },
      { name: t('solutions.home'), href: '/home' },
    ],
    legal: [
      { name: t('footer.legal.terms'), href: '/terms-conditions' },
      { name: t('footer.legal.privacy'), href: '/privacy-policy' },
      // { name: t('footer.legal.hostEarnings'), href: '/host-earnings' },
    ],
    social: [
      {
        name: 'Facebook',
        href: process.env.REACT_APP_FACEBOOK_URL,
        icon: (props: SVGIntrinsicProps) => <Facebook {...props} />,
      },
      // {
      //   name: 'Twitter',
      //   href: process.env.REACT_APP_TWITTER_URL,
      //   icon: (props: SVGIntrinsicProps) => <Twitter {...props} />,
      // },
      {
        name: 'LinkedIn',
        href: process.env.REACT_APP_LINKEDIN_URL,
        icon: (props: SVGIntrinsicProps) => <LinkedIn {...props} />,
      },
      {
        name: 'Email',
        href: `mailto:${process.env.REACT_APP_EMAIL}?subject=${t('contact.title')}`,
        icon: (props: SVGIntrinsicProps) => <EnvelopeIcon {...props} />,
      },
      // {
      //   name: 'Phone',
      //   href: `tel:${process.env.REACT_APP_PHONE}`,
      //   icon: (props: SVGIntrinsicProps) => <PhoneIcon {...props} />,
      // },
    ],
  }

  return (
    <footer className="bg-gray-900 dark:bg-gray-950" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <img className="h-12 md:h-16" src={logoForesta} alt="Foresta" />
            <p className="text-sm leading-6 text-gray-300">{t('hero.description')}</p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-500 hover:text-gray-400"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="grid grid-cols-2 md:gap-8">
              <div className="hidden md:grid"></div>
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">
                  {t('footer.solutions')}
                </h3>
                <ul className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-400 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="grid grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">{t('footer.legal')}</h3>
                <ul className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-sm leading-6 text-gray-400 hover:text-white"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="hidden md:flex md:flex-col mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">
                  {t('footer.download')}
                </h3>
                <div className="mt-6">
                  <StoreBadges display="vertical" />
                </div>
              </div>
            </div>
          </div>
          <div className="md:hidden flex justify-center mt-5 pt-5 border-t border-white/10">
            <StoreBadges display="horizontal" />
          </div>
        </div>
        <div className="mt-6 md:mt-16 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between space-y-4">
          <div className="space-y-2">
            <div className="text-xs leading-5 text-gray-300">{t('footer.incubatedAt')}</div>
            <div className="flex justify-center md:justify-start items-center space-x-6">
              <a href="https://2degres.com" target="_blank" rel="noreferrer">
                <DeuxDegres className="h-6 md:h-8 text-gray-500 hover:text-gray-400" />
                <span className="sr-only">MT Lab</span>
              </a>
              <a href="https://mtlab.ca" target="_blank" rel="noreferrer">
                <MTLab className="h-6 md:h-8 text-gray-500 hover:text-gray-400" />
                <span className="sr-only">MT Lab</span>
              </a>
              <a href="https://coopcarbone.coop" target="_blank" rel="noreferrer">
                <CoopCarbone className="h-4 md:h-6 text-gray-500 hover:text-gray-400" />
                <span className="sr-only">Coop Carbone</span>
              </a>
            </div>
          </div>
          <div className="flex justify-center md:justify-end text-xs leading-5 text-gray-300">
            &copy; Copyright {currentYear}
          </div>
        </div>
      </div>
    </footer>
  )
}
