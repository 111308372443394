import { BoltIcon } from '@heroicons/react/24/outline'

import { SocialEvseModel } from '../types/SocialEvseModel'
import charger from '../assets/img/charger.svg'
import CHAdeMo from './Icons/Connector/CHAdeMo'
import CCS from './Icons/Connector/CCS'
import J1772 from './Icons/Connector/J1772'
import Nema515 from './Icons/Connector/Nema515'
import Tesla from './Icons/Connector/Tesla'

interface Props {
  evses: Array<SocialEvseModel>
}

export enum ConnectorType {
  CCS = 'CCS',
  ChadeMo = 'CHADEMO',
  J1772 = 'J1772',
  Nema515 = 'NEMA515',
  Tesla = 'TESLA',
}

export default function LocationStations({ evses }: Props) {
  const getConnectorIcon = (connector: string) => {
    let icon = <></>

    switch (connector.toUpperCase()) {
      case ConnectorType.CCS:
        icon = <CCS />
        break
      case ConnectorType.ChadeMo:
        icon = <CHAdeMo />
        break
      case ConnectorType.J1772:
        icon = <J1772 />
        break
      case ConnectorType.Nema515:
        icon = <Nema515 />
        break
      case ConnectorType.Tesla:
        icon = <Tesla />
        break
    }

    return icon
  }

  return (
    <div className="flex flex-col md:flex-row gap-4">
      {evses.map((evse, index) => (
        <div
          key={`station-${index}`}
          className="flex rounded-2xl bg-slate-50 dark:bg-slate-900 w-full md:w-1/3 shadow-sm p-3"
        >
          <img src={charger} alt="Charger" className="w-12" />
          <div className="flex flex-col items-start justify-start gap-2 p-2 overflow-hidden">
            <strong className="text-lg truncate text-ellipsis">{evse.name}</strong>
            <div className="flex flex-col gap-2">
              {evse.connectors.map((connector, index) => (
                <div key={`connector-${index}`} className="flex gap-2">
                  {getConnectorIcon(connector.standard)}
                  <span>{connector.standard}</span>
                  <BoltIcon className="h-5 w-5" />
                  <span>{connector.powerKW.toFixed(1)} kW</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
