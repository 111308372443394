import Header from '../components/Header/SimpleHeader'
import HostEarnings from '../components/HostEarnings'
import Footer from '../components/Footer'

export default function HostEarningsPage() {
  return (
    <>
      <Header />
      <HostEarnings />
      <Footer />
    </>
  )
}
