import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Message, { MessageIntent } from './Message/Message'
import { LoadingIcon } from './Loading/LoadingIcon'

interface Props {
  code: string | undefined
}

export default function Unsubscribe({ code }: Props) {
  const { t } = useTranslation()
  const [success, setSuccess] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    const unsubscribe = async (code: string) => {
      const url = `${process.env.REACT_APP_API_URL}/common/unsubscribe/${code}`
      const options = { method: 'POST' }

      await fetch(url, options)
        .then((res) => {
          setSuccess(res.ok)
        })
        .catch((err) => {
          console.error('Error: ', err)
          setSuccess(false)
        })
    }

    if (code) unsubscribe(code)
  }, [code])

  return (
    <section className="bg-white/0 dark:bg-slate-800 py-10 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-10">
        <h1 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-6xl">
          {t('unsubscribe.title')}
        </h1>
        {success === undefined && (
          <div className="group flex items-center gap-2">
            <div className="w-8 h-8">
              <LoadingIcon color="text-gray-500" />
            </div>
            <span className="text-gray-500 text-lg">{t('loading')}</span>
          </div>
        )}
        {success === true && (
          <Message intent={MessageIntent.Success} title={t('unsubscribe.subtitle')} />
        )}
        {success === false && (
          <Message intent={MessageIntent.Error} title={t('error.title')}>
            {t('error.message')}
          </Message>
        )}
      </div>
    </section>
  )
}
