import LocaleSwitcher from '../LocaleSwitcher/LocaleSwitcher'
import logo from '../../assets/img/foresta-white.svg'

export default function SimpleHeader() {
  return (
    <header className="sticky top-0">
      <nav className="bg-gradient-to-r from-[#15c2c0] to-[#334155]">
        <div className="flex items-center px-4 md:px-10 justify-between">
          <a href="/">
            <img className="h-20 md:h-24 p-4 md:p-4" src={logo} alt="Foresta Logo" />
          </a>
          <LocaleSwitcher />
        </div>
      </nav>
    </header>
  )
}
