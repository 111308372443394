import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Reaptcha from 'reaptcha'
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

import { Language } from '../types/Language'
import { ContactMessage } from '../types/Contact'
import Message, { MessageIntent } from './Message/Message'
import { LoadingIcon } from './Loading/LoadingIcon'

interface Props {
  becomePartner?: boolean
}

export default function Contact({ becomePartner = false }: Props) {
  const { t, i18n } = useTranslation()
  const captchaRef = useRef<Reaptcha>(null)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [website, setWebsite] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')
  const [isVerified, setIsVerified] = useState(false)
  const [isMessageSent, setIsMessageSent] = useState<boolean | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const subjects = t<string, { returnObjects: true }, string[]>('contact.subjects', {
    returnObjects: true,
  })

  const verifyCaptcha = (response: string) => {
    console.log('reCAPTCHA response: ', response)
    setIsVerified(true)
  }

  const sendMessage = async (event: React.SyntheticEvent) => {
    event.preventDefault()

    // TODO: Use an enum for language
    const language = i18n.resolvedLanguage?.includes(Language.English) ? 1 : 2
    const url = `${process.env.REACT_APP_API_URL}/common/contactus`

    setIsLoading(true)
    setIsMessageSent(undefined)

    let contact: ContactMessage = {
      name,
      email,
      subject,
      message,
      language,
    }

    if (becomePartner) {
      contact = {
        ...contact,
        subject: 'Partnership',
        company: company,
        website: website,
        phone: phone,
      }
    }

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contact),
    })
      .then((res) => {
        setIsMessageSent(res.ok)
        setName('')
        setEmail('')
        setCompany('')
        setWebsite('')
        setPhone('')
        setSubject('')
        setMessage('')
      })
      .catch((err) => {
        console.error('Error: ', err)
        setIsMessageSent(false)
      })
      .finally(() => {
        captchaRef.current?.reset()
        setIsVerified(false)
        setIsLoading(false)
      })
  }

  return (
    <section id="contact" className="bg-white dark:bg-slate-800 py-10 sm:py-16">
      <div className="mx-auto max-w-2xl px-6 lg:px-8 space-y-5">
        <div className="flex flex-col items-center text-center gap-3">
          <h2 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-6xl">
            {t('contact.title')}
          </h2>
          <a
            className="text-gray-600 dark:text-gray-400 hover:text-brand"
            href={`mailto:${process.env.REACT_APP_EMAIL}?subject=${t('contact.title')}`}
          >
            <div className="flex items-center gap-x-2">
              <span className="flex-none">
                <EnvelopeIcon className="h-5 w-5" aria-hidden="true" />
              </span>
              <span>{process.env.REACT_APP_EMAIL}</span>
            </div>
          </a>
          <a
            className="text-gray-600 dark:text-gray-400 hover:text-brand"
            href={`tel:${process.env.REACT_APP_PHONE}`}
          >
            <div className="flex items-center gap-x-2">
              <span className="flex-none">
                <PhoneIcon className="h-5 w-5" aria-hidden="true" />
              </span>
              <span>{process.env.REACT_APP_PHONE}</span>
            </div>
          </a>
        </div>
        {isMessageSent === true && (
          <Message intent={MessageIntent.Success} title={t('contact.received')}>
            {t('contact.thank')}
          </Message>
        )}
        {isMessageSent === false && (
          <Message intent={MessageIntent.Error} title={t('error.title')}>
            {t('error.message')}
          </Message>
        )}
        <form onSubmit={sendMessage}>
          <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            <div className="col-span-full">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                {t('contact.name')}
              </label>
              <div className="mt-1">
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 px-2 bg-white dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6 focus:outline-none"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                {t('contact.email')}
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 px-2 bg-white dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6 focus:outline-none"
                />
              </div>
            </div>
            {becomePartner && (
              <div className="col-span-full">
                <label
                  htmlFor="company"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  {t('contact.company')}
                </label>
                <div className="mt-1">
                  <input
                    id="company"
                    name="company"
                    type="text"
                    required
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 px-2 bg-white dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6 focus:outline-none"
                  />
                </div>
              </div>
            )}
            {becomePartner && (
              <>
                <div className="col-span-full md:col-span-3">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    {t('contact.website')}
                  </label>
                  <div className="mt-1">
                    <input
                      id="website"
                      name="website"
                      type="url"
                      value={website}
                      onChange={(event) => setWebsite(event.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 px-2 bg-white dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="col-span-full md:col-span-3">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    {t('contact.phone')}
                  </label>
                  <div className="mt-1">
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 px-2 bg-white dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6 focus:outline-none"
                    />
                  </div>
                </div>
              </>
            )}
            {!becomePartner && (
              <div className="col-span-full">
                <label
                  htmlFor="subject"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  {t('contact.subject')}
                </label>
                <div className="mt-1">
                  <select
                    id="subject"
                    name="subject"
                    required
                    onChange={(event) => setSubject(event.target.value)}
                    value={subject}
                    className="block w-full rounded-md border-0 py-1.5 px-2 bg-white dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6 focus:outline-none"
                  >
                    <option value=""></option>
                    {subjects.map((item, index) => (
                      <option key={`subject-${index}`} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="col-span-full">
              <label
                htmlFor="message"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                {t('contact.message')}
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  required
                  value={message}
                  rows={3}
                  onChange={(event) => setMessage(event.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 px-2 bg-white dark:bg-white/5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6 focus:outline-none"
                />
              </div>
            </div>
            <div className="col-span-full">
              <Reaptcha
                ref={captchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onVerify={verifyCaptcha}
              />
            </div>
            <div className="col-span-full">
              <button
                type="submit"
                disabled={!isVerified || isLoading}
                className="rounded-md bg-brand px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand cursor-pointer disabled:bg-gray-500"
              >
                <div className="flex flex-row gap-2">
                  {isLoading ? (
                    <>
                      <div className="w-5 h-5">
                        <LoadingIcon color="text-white" />
                      </div>
                      <div>{t('contact.sending')}</div>
                    </>
                  ) : (
                    <div>{t('contact.send')}</div>
                  )}
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}
