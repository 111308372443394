import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import AbstractShape from '../Shapes/AbstractShape'
import StoreBadges from '../Badges/StoreBadges'
import LocaleSwitcher from '../LocaleSwitcher/LocaleSwitcher'
import logo from '../../assets/img/foresta-white.svg'
import background from '../../assets/img/bg/foresta-bg.jpg'

export default function Header() {
  const { t } = useTranslation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const navigation = [
    { name: t('menu.solutions'), href: '#solutions' },
    { name: t('menu.becomePartner'), href: '#partner' },
    { name: t('menu.contact'), href: '#contact' },
  ]

  return (
    <div className="bg-gray-900">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <img className="h-16 w-auto" src={logo} alt="Foresta" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">{t('openMenu')}</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-white"
              >
                {item.name}
              </a>
            ))}
            <a
              href={process.env.REACT_APP_LOGIN_URL}
              target="_blank"
              rel="noreferrer"
              className="text-sm font-semibold leading-6 text-white"
            >
              {t('menu.login')} <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <LocaleSwitcher />
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Foresta</span>
                <img className="h-16 w-auto" src={logo} alt="Foresta" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">{t('closeMenu')}</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/25">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href={process.env.REACT_APP_LOGIN_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                  >
                    {t('menu.login')}
                  </a>
                </div>
                <div className="py-6">
                  <LocaleSwitcher />
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>

      <div className="relative isolate overflow-hidden pt-14">
        <img
          src={background}
          alt="Foresta"
          className="absolute inset-0 -z-10 h-full w-full object-cover brightness-50"
        />
        <AbstractShape />
        <div className="mx-auto max-w-6xl px-6 lg:px-8 py-44 sm:py-60 lg:py-72">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              {t('hero')}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">{t('hero.description')}</p>
            <div className="mt-6 flex flex-col items-center md:hidden">
              <StoreBadges display="horizontal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
