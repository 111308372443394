import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { Language } from '../../types/Language'
import WaveShape from '../Shapes/WaveShape'
import StoreBadges from '../Badges/StoreBadges'
import LocaleSwitcher from '../LocaleSwitcher/LocaleSwitcher'
import logo from '../../assets/img/foresta-white.svg'
import welcome from '../../assets/img/bg/welcome-bg.jpg'
import phoneEn from '../../assets/img/welcome/mockup-en.png'
import phoneFr from '../../assets/img/welcome/mockup-fr.png'

export default function HomeHeader() {
  const { t, i18n } = useTranslation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const navigation = [
    { name: t('menu.how-it-works'), href: '#how-it-works' },
    { name: t('menu.faq'), href: '#faq' },
    { name: t('menu.contact'), href: '#contact' },
  ]

  return (
    <div className="bg-gray-900">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <img className="h-16 w-auto" src={logo} alt="Foresta" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">{t('openMenu')}</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-white"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <LocaleSwitcher />
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Foresta</span>
                <img className="h-16 w-auto" src={logo} alt="Foresta" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">{t('closeMenu')}</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/25">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="space-y-2 py-6">
                  <LocaleSwitcher />
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>

      <div className="relative isolate overflow-hidden pt-2 md:pt-14 bg-overlay">
        <img
          src={welcome}
          alt="Foresta"
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
        <div className="mx-auto max-w-6xl px-4 pt-24">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex">
                <span className="rounded-full px-3 py-1 text-sm leading-6 text-white/75 ring-1 ring-white/30">
                  {t('menu.solutions.home')}
                </span>
              </div>
              <h1 className="text-white text-4xl md:text-6xl">{t('home.title')}</h1>
              <p className="text-white text-lg">{t('home.subtitle')}</p>
              <div className="flex justify-center md:justify-start">
                <StoreBadges display="horizontal" />
              </div>
            </div>
            <div className="flex justify-center md:justify-end">
              <img
                src={i18n.resolvedLanguage === Language.French ? phoneFr : phoneEn}
                alt={t('home.phone')}
                className="max-w-48 md:max-w-72 md:w-96 z-10"
              />
            </div>
          </div>
        </div>
        <div className="hidden md:block absolute -bottom-2 left-0 right-0">
          <WaveShape />
        </div>
      </div>
    </div>
  )
}
