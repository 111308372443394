import { useTranslation } from 'react-i18next'

import easyToUse from '../assets/img/features/easyToUse.svg'
import payAsYouGo from '../assets/img/features/payAsYouGo.svg'
import flexibleBooking from '../assets/img/features/flexibleBooking.svg'

export default function Features() {
  const { t } = useTranslation()

  const features = [
    {
      image: easyToUse,
      title: t('features.feature.1.title'),
      description: t('features.feature.1.description'),
    },
    {
      image: payAsYouGo,
      title: t('features.feature.2.title'),
      description: t('features.feature.2.description'),
    },
    {
      image: flexibleBooking,
      title: t('features.feature.3.title'),
      description: t('features.feature.3.description'),
    },
  ]

  return (
    <section className="bg-white/0 dark:bg-black py-10 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-10">
        <h2 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-6xl text-center">
          {t('features.title')}
        </h2>
        <div className="flex flex-col md:flex-row gap-6">
          {features.map((feature, index) => (
            <div
              key={`feature-${index}`}
              className="flex flex-col w-full md:w-1/3 items-center justify-between text-center space-y-2 shadow-md p-4 rounded-2xl bg-slate-50 dark:bg-slate-900"
            >
              <img src={feature.image} alt={t('features.feature.1.title')} className="w-32 p-2" />
              <div>
                <h3 className="text-xl font-medium text-slate-900 dark:text-slate-100">
                  {feature.title}
                </h3>
                <p className="text-slate-500 p-3">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
