import { useTranslation } from 'react-i18next'

import Markdown from './Markdown/Markdown'
import CancellationPolicy from './CancellationPolicy'

const FILE_NAME = 'terms-conditions.md'

export default function Terms() {
  const { t } = useTranslation()

  return (
    <section className="bg-white/0 dark:bg-slate-800 py-10 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-10">
        <div>
          <h1 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100 sm:text-6xl">
            {t('terms.title')}
          </h1>
          <p className="text-2xl font-medium text-gray-600 dark:text-gray-400">
            {t('terms.subtitle')}
          </p>
        </div>
        <Markdown fileName={FILE_NAME} />
        <CancellationPolicy />
      </div>
    </section>
  )
}
